import React, {Component} from 'react';

/* React-admin components */
import { Title, TextInput, NumberInput, ReferenceInput, BooleanInput,
	DateInput, SelectInput, SimpleForm, LongTextInput, ArrayInput, SimpleFormIterator, FormDataConsumer } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

/* Material UI components */
import Typography from '@material-ui/core/Typography';

import slugify from 'slugify';

/* TMP (use for generate pattern examples) */
const lastnameList = [
  "Adams",
  "Brown",
  "Campbell",
  "Davis",
  "Evans",
  "Foster",
  "Griffin",
  "Harris",
  "Ingram",
  "Jones",
  "King",
  "Lewis",
  "Miller",
  "Nelson",
  "Owens",
  "Parker",
  "Quinn",
  "Robinson",
  "Smith",
  "Thomas",
  "Underwood",
  "Vasquez",
  "Williams",
  "Xiong",
  "Young",
  "Zimmerman"
];

const firstnameList = [
  "Andrew",
  "Brian",
  "Christopher",
  "David",
  "Edward",
  "Frank",
  "George",
  "Heather",
  "Irene",
  "James",
  "Karen",
  "Lisa",
  "Mary",
  "Nancy",
  "Oscar",
  "Patricia",
  "Quentin",
  "Robert",
  "Susan",
  "Thomas",
  "Ursula",
  "Victoria",
  "William",
  "Xavier",
  "Yvonne",
  "Zachary",
];

class PatternCreateModel extends Component {

	constructor(props) {
    super(props);

    this.state = {
      customizable_fields: this.props.fields || [],
      styles: this.props.styles || [],
      models: this.props.styles.map(style => {
        return {
          name: style.name,
          index: style.index,
          file: style.file,
          fields: [
            { fields_data: style.fields.map((field, index) => {
              const fieldObj = this.props.fields[index];
              return {
                id: index + 1,
                name: fieldObj.name,
                value: (fieldObj.name.toLowerCase() == 'firstname' || fieldObj.name.toLowerCase() == 'first name') ?
                    firstnameList[style.index % 26]
                  : 
                    fieldObj.name.toLowerCase().includes('name') ?
                      lastnameList[style.index % 26]
                    :
                      ''
              };
            })
          }]
        }; 
      }),
    };

    this.getData = this.getData.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
	};

	handleGenericValueChange(value, name) {
		this.setState({
      [name]: value
    });
	}

	getData() {
		return this.state.models;
	}

  handleChange(data) {
    // THIS IS A WORKAROUND / QUICK WIN, BUT IT WORKS SO BE HAPPY :D
    if (data && data.models) {
      data.models.forEach((model) => {
        const currentStyleId = model.index;
        model.fields.forEach((field) => {
          if (!Object.keys(field).length) {
            const fieldsIds = data.pattern_styles.find((style) => { return style.index == currentStyleId }).fields;
            const fieldsDataContent = [];
            fieldsIds.forEach((id) => {
              fieldsDataContent.push({
                id: '1',
                name: data.customizable_fields[id].name,
                value: ''
              });
            });
            field.fields_data = fieldsDataContent;
          }
        });
      });
    }
    this.setState({models: data.models});
  }

	render() {

		const { styles, models } = this.state;

		return (
			<div>
				<SimpleForm onChange={ (data) => this.handleChange(data)} toolbar={<span></span>}>
			    <Typography style={{fontSize: 20, fontFamily: 'Roboto', fontWeight: 'bold'}}>
            Pattern models
          </Typography>

          <div style={{padding: '10px 25px 10px 25px', width: 'auto', backgroundColor: 'rgba(227, 242, 253, 0.3)'}}>
            <ArrayInput source="models" defaultValue={models} style={{width: '100%'}}>
              <SimpleFormIterator disableRemove={true} disableAdd={true}>
                <TextInput disabled type="text" name="name" style={{margin: 0}} source="name" />
                <TextInput disabled type="number" name="index" style={{margin: 0}} source="index" />

                <FormDataConsumer>
                  {({
                    formData, // The whole form data
                    scopedFormData, // The data for this item of the ArrayInput
                    getSource, // A function to get the valid source inside an ArrayInput
                    ...rest,
                  }) => {
                    return (
                      <div style={{padding: '10px 25px 10px 25px', width: 'auto', backgroundColor: 'rgba(232, 245, 233, 0.3)'}}>
                        <ArrayInput style={{paddingTop: '10px'}} source={getSource('fields')}>
                          <SimpleFormIterator>
                            <FormDataConsumer>
                              {({
                                formData, // The whole form data
                                scopedFormData, // The data for this item of the ArrayInput
                                getSource, // A function to get the valid source inside an ArrayInput
                                ...rest,
                              }) => {
                                return (
                                  <ArrayInput style={{paddingTop: '10px'}} source={getSource('fields_data')}>
                                    <SimpleFormIterator disableRemove={true} disableAdd={true}>
                                      <FormDataConsumer>
                                        {({
                                          formData, // The whole form data
                                          scopedFormData, // The data for this item of the ArrayInput
                                          getSource, // A function to get the valid source inside an ArrayInput
                                          ...rest,
                                        }) => {
                                          return (
                                            <div>
                                              <TextInput label={false} disabled type="text" name="id" style={{margin: 0}} source={getSource('id')} />
                                              <TextInput label={false} disabled type="text" name="name" style={{margin: 0}} source={getSource('name')} />
                                              <TextInput label="Value" type="text" name="value" style={{margin: '0 0 10px 0'}} source={getSource('value')} />
                                            </div>
                                          );
                                        }
                                        }
                                      </FormDataConsumer>
                                    </SimpleFormIterator>
                                  </ArrayInput>
                                );
                              }
                              }
                            </FormDataConsumer>
                          </SimpleFormIterator>
                        </ArrayInput>
                      </div>
                    );
                  }
                  }
                </FormDataConsumer>

              </SimpleFormIterator>
            </ArrayInput>
          </div>
          
			  </SimpleForm>
			</div>
		);
	}
}

export default PatternCreateModel;