import React, {Component} from 'react';

/* React-admin components */
import { Title, TextInput, NumberInput, ReferenceInput, BooleanInput,
	DateInput, SelectInput, SimpleForm, LongTextInput, ArrayInput, SimpleFormIterator, FormDataConsumer } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

/* Material UI components */
import Typography from '@material-ui/core/Typography';

class PatternCreateFaqs extends Component {

	constructor(props) {
    super(props);

    this.state = {
      faqs: this.props.faqs || []
    };

    this.getData = this.getData.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

	getData() {
		return this.state.faqs;
	}

	handleChange(data) {
		this.setState({faqs: data.faqs});
	}

	render() {

		const {faqs} = this.state;

		return (
			<div>
				<SimpleForm toolbar={<span></span>} onChange={ (data) => this.handleChange(data)} >
			    <Typography style={{fontSize: 20, fontFamily: 'Roboto', fontWeight: 'bold'}}>
            FAQS
          </Typography>
          
          <div style={{padding: '10px 25px 10px 25px', width: 'auto', backgroundColor: 'rgba(227, 242, 253, 0.3)'}}>
				    <ArrayInput defaultValue={faqs} source="faqs" style={{width: '100%'}}>
					    <SimpleFormIterator>
				        <TextInput type="text" name="question" style={{margin: 0, width: '100%'}} source="question" />
				        <TextInput type="text" name="answer" style={{margin: 0, width: '100%'}} source="answer" />
					    </SimpleFormIterator>
						</ArrayInput>
					</div>
			  </SimpleForm>
			</div>
		);
	}
}

export default PatternCreateFaqs;