import React from 'react';
import { Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';

import myDataProvider from './myDataProvider.js'
import './App.css';
import customRoutes from './customRoutes';

/* AUTH */
import authProvider from './auth/authProvider';
import loginPage from './auth/loginPage';

/* Category */
import CategoryList from './categories/list';
import CategoryCreate from './categories/create';
import CategoryEdit from './categories/edit';

/* Pattern */
import PatternList from './patterns/list';
import PatternShow from './patterns/show';

/* ICONS */
import FolderIcon from '@material-ui/icons/Folder';
import ExtensionIcon from '@material-ui/icons/Extension';

import createHistory from 'history/createBrowserHistory';

const URL_API = process.env.REACT_APP_NODE_ENV == "production" ? 'https://api.theholzbrothers.com' : 'http://api.theholzbrothers.local';
const history = createHistory();

const dataProvider = myDataProvider(URL_API + '/admin/v1');
const App = () => (
  <Admin history={history} customRoutes={customRoutes} locale="en" loginPage={loginPage} authProvider={authProvider} dataProvider={dataProvider}>
    <Resource name="categories" list={CategoryList} create={CategoryCreate} edit={CategoryEdit} icon={FolderIcon} />
    <Resource name="patterns" list={PatternList} /* create={CreateGuesser} */ edit={EditGuesser} show={PatternShow} icon={ExtensionIcon} />
  </Admin>
);

export default App;