import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/* React-admin components */
import { List, Datagrid, TextField, NumberField, EditButton, CardActions,
  CreateButton, RichTextField, ChipField } from 'react-admin';

/* Material UI components */
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// Colors
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
// Icons
import ExtensionIcon from '@material-ui/icons/Extension';

/* Utils */
import { stringify } from 'query-string';

/* Filter */
import { CategoryFilter } from './filter';


const styles = {};

const rowStyle = (record, index, defaultStyle = {}) => {
  if (record.deleted === false)
    return {
      ...defaultStyle,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.deleted === true)
    return {
      ...defaultStyle,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  return defaultStyle;
};

rowStyle.propTypes = {
  record: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  defaultStyle: PropTypes.object.isRequired,
};

const CategoryActions = ({ basePath }) => (
  <CardActions>
    <CreateButton label="Create a new category" basePath={basePath} />
  </CardActions>
);

CategoryActions.propTypes = {
  basePath: PropTypes.string,
};

const LinkToRelatedPatterns = ({ record }) => (
  <Button
    size="small"
    color="primary"
    component={Link}
    to={{
      pathname: '/patterns',
      search: stringify({
        page: 1,
        perPage: 25,
        sort: 'created_at',
        order: 'DESC',
        filter: JSON.stringify({ status: 'published', category_id: record.id }),
      }),
    }}
  >
    <ExtensionIcon style={{fontSize: '20px'}} />
    Patterns
  </Button>
)

LinkToRelatedPatterns.propTypes = {
  record: PropTypes.object
};

class CategoryList extends Component {

  render() {
    return (
      <List
        filterDefaultValues={{ deleted: false }}
        actions={<CategoryActions />}
        filters={<CategoryFilter />}
        {...this.props}>
        <Datagrid rowStyle={rowStyle}>
          <TextField source="id" />
          <TextField source="name" />
          <NumberField source="priority" />
          <RichTextField source="description" />
          <ChipField source="tag" />
          <LinkToRelatedPatterns />
          <EditButton />
        </Datagrid>
      </List>
    );
  }
}

CategoryList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CategoryList);