import React, {Component} from 'react';

/* Material UI components */
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
// Icons
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
// Colors
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

/* Utils */
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import axios from 'axios';
import uuidv4 from 'uuid/v4';

import {google} from 'googleapis';

// TMP
const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

const getColor = (props) => {
  if (props.isDragAccept) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isDragActive) {
      return '#2196f3';
  }
  return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

class UploadDropZone extends Component {

	constructor(props) {
    super(props);

    this.state = {
      files: this.props.files,
      folder: null
    };

    this.onDrop = this.onDrop.bind(this);
    this.sendFiles = this.sendFiles.bind(this);
    this.createFolder = this.createFolder.bind(this);
    this.updateAllFilenames = this.updateAllFilenames.bind(this);
  }

  componentDidMount() {
  	const SERVICE_ACCOUNT = 'thb-admin-pattern@webstore-1570511549402.iam.gserviceaccount.com';
  	const GOOGLE_APIS_PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDZqejRzJtGbu/n\nj/mJBZCgMQhQHKpmDLdJISKv1PQkmBpUUwgIsC1eoMccz0SQq1qkYeuInuHkOp4X\nmdvs5qQAWIDoJE9VESQfLvWU73vfOm7BljGpXffutGgoEmrW7bQedAdJOmQeDsff\nJ44fIKB2NKdKI/85pFp26BECxvurUFzgEfhemxkMEMdp7XIWEPPzrA+DilSt2cVf\ntl9j82/n1inYlPHRX+81GpEah4wr1LbFl+xU2tTyCP2goTh8PCdVGrzfkY/NPEIz\ntIF7aVPA6106TjgQbwftEGhv9LBBro0oP8i//jJ/EaACbOhJ0oDx0eVqZORvWHCf\nlEL2LsENAgMBAAECggEAHZ2NrQYO1n1/rN0dEbStIe6p9+R+ltYCFjQo+Q2bjmMX\nqgOGI1B9S9PKY7Qb2ydFdPpRxsv/ZkNHgWSQ1OHozJc+KtfN54AmjwufpH0VOKFF\nspdR+H5tno/zYAhpewbqq/4Tg7YrKx/NuCI0py2UR6EQZfkR3/Dp0iXvMpaKZZuQ\nJQnQG7YtXinJQZ2TmNHP9Om4vtYUn3ynHiC/P1OKg8AGmEj8v5nvP8V5r35F9Gwj\nQvBT/gN/aRydWLbW9zpVPUaGabyvA3rr5FOm3/d5de1pXoaG+E4jZpTOuW9Hb7PY\nXqbPwj+2BVDAFRpfLVvB9QXhC2T9fnYAkcEsVP1B7QKBgQD/9GdC4hNYR35pnPtG\nId+JfAmH4tuZfqAdfuA6xo8SWw1RZGOeRtPtoGNR9RFjGrx8jx/6etERQPybFkGC\nr2bMBpvcyomyTaCy0dGOEmcizw7tMHcQnCTjdzRkQavTaj+jbrYGnO5Yf51NNb1U\n6tUU/oinqaDlHNfWtFizS1Lg6wKBgQDZs8Vu2MPIG45kenDF8E43t6MObhoXJwkB\nSPf6+/617+4+ZC9YLNlhvIsbhORkEqvF6NnCTKjx3gGbFpWT69wIUc3OTbqHn+Dw\nhvaVBnfxr9VlN4UPbfEYS7VWFMpuVx+sapUk5RgN/U7KAR/gx2Dj2KYOX/xirADf\nhFikIeYn5wKBgQDNjeI//H+8re3/s2q48AstxseAsdfada7xASvjCANuomEXtJ+s\nRQV+GQiqkT3dcWNeRTNRXcD5Mk+r3TvPUD4mLEiQELh00ZL1CTxZ9L2PXuqF4h48\n4KlfZCRXP8ZWi0WAYzEcLnvb5uDVWQssX0uxCJmGzNvCJF/SthNmKl/s8QKBgHNf\nnoZ8QmXOlMwTR6jfDdThcXy4RQN4IIGZ1jIhUn/BRBW0qpKkUiBF98MBzrhqXLZq\nDN24GuDzsw4qDeNy4A1YrSVkdDJkPKW8IPohj3d6pDEcF1xx+4/Qr62LHy0S5ypM\n4fXHo9PmJtdPeUF66JJXwsljfTIdzeinDwMNJBiJAoGBAIOxGLtbJYiqBnGSA9x8\n8eCvYngvhSyFbTsOdhofE0FNrekLdhGqeAHVYnyVCBp7vTmASGWQgJclWtkgtXaM\nmT6szlkVZux3rtvsprdbNM6qonrBmUd2nBhQ0KIsusIULeQCWi1gRFQj9TCRSOj7\n88d1q7OLvQ2h27jo7G/VURpk\n-----END PRIVATE KEY-----\n";

		const jwt = new google.auth.JWT(
      SERVICE_ACCOUNT,
      null,
      GOOGLE_APIS_PRIVATE_KEY.replace(/\\n/g, '\n'),
      ['https://www.googleapis.com/auth/drive']
    );

		jwt.authorize((err, response) => {
			this.setState({token: response.access_token});
		});
  }

	onDrop(acceptedFiles) {
	  this.setState({ files: [this.state.files, acceptedFiles ].flat()});
	  if (this.state.folder == null) {
	  	this.createFolder(this.state.files);
	  } else {
	  	this.sendFiles(this.state.files, this.state.folder);
	  }
	}

	sizeDisplay(size) {
		if (size < 1000000) {
			return (
				<span>&nbsp;{(Math.round(size * 0.001))} Ko</span>
			);
		} else {
			return (
				<span>&nbsp;{(Math.round(size * 0.000001))} Mo</span>
			);
		}
	}

	removeFile(e, uuid) {
		const files = this.state.files;
		const removedFileIndex = this.state.files.findIndex(file => file.uuid === uuid);
		const fileId = this.state.files[removedFileIndex].uuid;

		files.splice(removedFileIndex, 1);

		this.setState({ files: files });
		this.props.removeFile(fileId);
	}

	createFolder(files) {
		const folderName = uuidv4();
		const folderMetadata = {
		  'name': folderName, // Foldername at Google Drive
		  'parents': ['1fgWJMGcAqWtZhZNNm9mUBKoqeN97Y_Pg'], // Folder ID at Google Drive
		  'mimeType': 'application/vnd.google-apps.folder'
		};

		const formFolder = new FormData();
		formFolder.append('metadata', new Blob([JSON.stringify(folderMetadata)], {type: 'application/json'}));

		const xhr = new XMLHttpRequest();
		xhr.open('post', 'https://www.googleapis.com/upload/drive/v3/files?fields=id');
		xhr.setRequestHeader('Authorization', 'Bearer ' + this.state.token);
		xhr.responseType = 'json';
		xhr.onload = () => {
			this.setState({ folder: folderName });
			let folderParentId = xhr.response.id; // folderID at Google Drive
			this.props.pushFolder(folderName);
			this.sendFiles(files, folderParentId);
			this.setState({folder: folderParentId})
		};
		xhr.send(formFolder);
	}

	async updateAllFilenames (files, json, callback) {
		for (let file of files) {
			this.updateFilename(json.patternStyles.find(style => {
				return style.uuid == file.uuid
			}).name, file.id);
			await sleep(150);
		}
		this.updateFoldername(json.pattern.tag, this.state.folder, callback)
	}

	updateFoldername (foldername, folderId, callback) {
		const metadata = {
  			'name': foldername, // Foldername at Google Drive
  			'fileId': folderId
  		}

		const form = new FormData();
		form.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));

		const xhr = new XMLHttpRequest();
		xhr.open('PATCH', `https://www.googleapis.com/upload/drive/v3/files/${folderId}?uploadType=multipart&fields=id`);
		xhr.setRequestHeader('Authorization', 'Bearer ' + this.state.token);
		xhr.responseType = 'json';
		xhr.onload = () => {
			callback(); // ==> Send pattern to API
		};
		xhr.send(form);
	}

	updateFilename (filename, fileId) {
		const metadata = {
  			'name': filename, // Filename at Google Drive
  			'fileId': fileId
  		}

		const form = new FormData();
		form.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));

		const xhr = new XMLHttpRequest();
		xhr.open('PATCH', `https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=multipart&fields=id`);
		xhr.setRequestHeader('Authorization', 'Bearer ' + this.state.token);
		xhr.responseType = 'json';
		xhr.onload = () => {};
		xhr.send(form);
	}

  sendFiles(files, folderParentId) {
  	for(let i = 0; i < files.length; i++) {

  		const file = files[i];
  		const tmpFile = files[i];
  		let nfiles = Object.assign([], this.state.files);
  		if (file.uploaded === true || file.startedUpload === true) {
  			continue;
  		}

  		this.props.pushWaitingFile();
  		file.startedUpload = true;
  		file.uuid = uuidv4();
  		nfiles[i] = file;
  		this.setState({files: nfiles});

  		

  		const metadata = {
  			'name': file.uuid, // Filename at Google Drive
		    'parents': [folderParentId], // Folder ID at Google Drive
  		}

			const form = new FormData();
			form.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
			form.append('file', tmpFile);

			const xhr = new XMLHttpRequest();
			xhr.open('post', 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id');
			xhr.setRequestHeader('Authorization', 'Bearer ' + this.state.token);
			xhr.responseType = 'json';
			xhr.onload = () => {
		    let nfiles = Object.assign([], this.state.files);
			  const fileIndex = nfiles.findIndex(f => f.uuid === file.uuid);

				nfiles[fileIndex].uploaded = true;
				nfiles[fileIndex].id = xhr.response.id; // fileID at Google Drive 
				this.setState({files: nfiles});
				this.props.pushFile(file);
			};
			xhr.send(form);
  	}
  }
  
  render() {
	  const acceptedFilesItems = this.state.files.map((file, index) => (
	    <li key={file.path} style={{display: 'flex', alignItems: 'center', height: 30}}>
	      {file.path} - {this.sizeDisplay(file.size)}
      	{ file.uploaded ?
      			<div style={{display: 'flex', alignItems: 'center', height: 30}}>
	      			<Tooltip placement="top" title='Successfully uploaded to the server'>
	      				<DoneIcon style={{color: green[500], padding: '0 0 0 5px'}} />
	      			</Tooltip>
	      			<Tooltip placement="right" title="Delete">
				      	<IconButton onClick={event => this.removeFile(event, file.uuid)} style={{width: 30, height: 30, marginLeft: 3}}>
				          <DeleteIcon style={{color: red[500], fontSize: 20}} />
				        </IconButton>
			        </Tooltip>
			      </div>
      		: 
      			<CircularProgress style={{width: 16, height: 16, marginLeft: 6}} color="primary" />
      	}
	    </li>
	  ));

	  return (
	  	<Dropzone multiple={true} onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => (
			    <div className="container">
			      <Container {...getRootProps()}>
			        <input {...getInputProps()} />
			        <p style={{textAlign: 'center'}}>Drag some files here, or click to select files.<br /></p>
			      </Container>
			      <div>
				      <h4>Files</h4>
				      <ul>
				        {acceptedFilesItems}
				      </ul>
				    </div>
			    </div>
			  )}
      </Dropzone>
	  );
  }
}

export default UploadDropZone;