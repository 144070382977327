import React, {Component} from 'react';

/* React-admin components */
import { Title, TextInput, NumberInput, ReferenceInput, BooleanInput,
	DateInput, SelectInput, SimpleForm, LongTextInput, ArrayInput, SimpleFormIterator, FormDataConsumer } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

/* Material UI components */
import Typography from '@material-ui/core/Typography';

import slugify from 'slugify';

const TYPE_FIELDS = [
    { id: 'case', name: 'case' },
    { id: 'scale', name: 'scale' },
    { id: 'maxlength', name: 'maxlength' }
	]

class PatternCreateCustomizableFields extends Component {

	constructor(props) {
    super(props);

    this.state = {
      customizable_fields: this.props.data || []
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
  	let numberOfFields = this.state.customizable_fields.length;

    setInterval(() => {
    	// New field added
    	if (numberOfFields < this.state.customizable_fields.length) {
    		let fields = this.state.customizable_fields;
		    fields[fields.length - 1].field_specifications = [
			    {
			    	type: 'case',
			    	value: 'uppercase'
			    },
			    {
			    	type: 'scale',
			    	value: 'yes'
			    },
			    {
			    	type: 'maxlength',
			    	value: ''
			    }
		    ];
		    this.setState({customizable_fields: fields});
    	}
    	numberOfFields = this.state.customizable_fields.length;
    }, 1000);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
	};

	handleGenericValueChange(value, name) {
		this.setState({
      [name]: value
    });
	}

	getData() {
		return this.state.customizable_fields;
	}

	render() {

		return (
			<div>
				<SimpleForm onChange={ (data) => this.setState({customizable_fields: data.customizable_fields})} toolbar={<span></span>}>
			    <Typography style={{fontSize: 20, fontFamily: 'Roboto', fontWeight: 'bold'}}>
            Customizable fields
          </Typography>
          
          <div onChange={(e) => this.handleInputChange(e)} style={{padding: '10px 25px 10px 25px', width: 'auto', backgroundColor: 'rgba(227, 242, 253, 0.3)'}}>
				    <ArrayInput source="customizable_fields" style={{width: '100%'}}>
					    <SimpleFormIterator>
				        <LongTextInput name="name" style={{width: '100%', margin: '0 0 10px 0'}} onChange={(e) => this.handleInputChange(e)} source="name" />
					    
					    	<FormDataConsumer>
                  {({
                    formData, // The whole form data
                    scopedFormData, // The data for this item of the ArrayInput
                    getSource, // A function to get the valid source inside an ArrayInput
                    ...rest,
                  }) => {
                  	return (
                  		<div style={{padding: '10px 25px 10px 25px', width: 'auto', backgroundColor: 'rgba(232, 245, 233, 0.3)'}}>
												<ArrayInput style={{paddingTop: '10px'}} source={getSource('field_specifications')}>
											    <SimpleFormIterator>
										        <SelectInput source="type" choices={TYPE_FIELDS} />
														<TextInput type="text" name="value" style={{margin: '0 0 10px 0'}} onChange={(e) => this.handleInputChange(e)} source="value" />
											    </SimpleFormIterator>
												</ArrayInput>
											</div>
                  	);
                  }
                  }
              	</FormDataConsumer>

					    </SimpleFormIterator>
						</ArrayInput>
					</div>

			  </SimpleForm>
			</div>
		);
	}
}

export default PatternCreateCustomizableFields;