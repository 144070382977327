import React, {Component} from 'react';

/* React-admin components */
import { Title, TextInput, NumberInput, ReferenceInput, BooleanInput, CheckboxGroupInput, 
	DateInput, SelectInput, SimpleForm, LongTextInput, ArrayInput, SimpleFormIterator, FormDataConsumer } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

/* Material UI components */
import Typography from '@material-ui/core/Typography';

import slugify from 'slugify';

class PatternMapFieldsToPatternStyle extends Component {

	constructor(props) {
    super(props);

    this.state = {
      pattern_styles: this.props.styles.map(style => {
      	return {
      		name: style.name,
      		index: style.index,
      		minHeight: style.minHeight,
  			  minWidth: style.minWidth,
      		file: style.file,
      		fields: style.fields || this.props.fields.map((field, index) => { return parseInt(index) + 1; })
      	}; 
      }),
      fields_list: this.props.fields.map((field, index) => {
      	return {
      		id: parseInt(index) + 1,
      		name: `${field.name} (${field.field_specifications && field.field_specifications.map(spec => {
      			return `${spec.type}: ${spec.value}`
      		}).join(' - ')})`
      	}
      }),
      files: this.props.files || []
    };

    this.getData = this.getData.bind(this);
  }

	getData() {
		return this.state.pattern_styles;
	}

	render() {

		const {pattern_styles, fields_list} = this.state;

		return (
			<div>
				<SimpleForm onChange={ (data) => this.setState({pattern_styles: data.pattern_styles})} toolbar={<span></span>}>
			    <Typography style={{fontSize: 20, fontFamily: 'Roboto', fontWeight: 'bold', width: '100%'}}>
            For every pattern styles, select its customizable fields
          </Typography>
          
          <div style={{padding: '10px 25px 10px 25px', width: 'auto', backgroundColor: 'rgba(227, 242, 253, 0.3)'}}>
				    <ArrayInput defaultValue={pattern_styles} source="pattern_styles" style={{width: '100%'}}>
					    <SimpleFormIterator disableRemove={true} disableAdd={true}>
				        <TextInput disabled type="text" name="name" style={{margin: 0}} source="name" />
				        <TextInput disabled type="number" name="index" style={{margin: 0, display: 'none'}} source="index" />
				        <CheckboxGroupInput source="fields" choices={fields_list} />
					    </SimpleFormIterator>
						</ArrayInput>
					</div>

			  </SimpleForm>
			</div>
		);
	}
}

export default PatternMapFieldsToPatternStyle;