import React, {Component} from 'react';
import PropTypes from 'prop-types';

/* React-admin components */
import { Title, TextInput, NumberInput, ReferenceInput, BooleanInput, DateInput, SelectInput, SimpleForm } from 'react-admin';

/* Material UI components */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

/* Utils */
import axios from 'axios';

const styles = theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  fullWidth: {
    width: '100%'
  }
});

const CLIENT_ID = "766891450409970";
const CLIENT_SECRET = "6d6aec5c2fbc78a052fadd54b73da973";
const USER_ID = "428880641339192";
const URL_REDIRECT = process.env.REACT_APP_NODE_ENV == "production" ? 'https://admin.theholzbrothers.com' : 'http://localhost:3000';

function getUrlVars() {
    let vars = {};
    let parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

class Connect extends Component {

	constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (getUrlVars()["code"] && getUrlVars()["state"] == "facebook#_=_") {
      this.getUserAccessTokenFromCode(getUrlVars()["code"]);
    }
  }

  getUserAccessTokenFromCode(code) {
    axios.get(`https://graph.facebook.com/oauth/access_token?client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&redirect_uri=${URL_REDIRECT}/social/connect&code=${code}`)
    .then(response => {
        this.getUserLongAccessToken(response.data.access_token);
      });
  }

  getUserLongAccessToken(accessToken) {
    axios.get(`https://graph.facebook.com/oauth/access_token?grant_type=fb_exchange_token&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&fb_exchange_token=${accessToken}`)
      .then(response => {
        let expirationDate = new Date();

        expirationDate.setSeconds(expirationDate.getSeconds() + response.data.expires_in);
        // Save token and expiration date in local storage
        window.localStorage.setItem('userLongAccessToken', response.data.access_token);
        window.localStorage.setItem('userLongAccessTokenExpiration', expirationDate);
        this.getPageAccessToken(response.data.access_token, accessToken);
      });
  }

  getPageAccessToken(userLongAccessToken, accessToken) {
    axios.get(`https://graph.facebook.com/${USER_ID}/accounts?access_token=${accessToken}`)
      .then(response => {
        // TODO: get page id
        const pageAccessToken = response.data.data[0].access_token;
        const pageId = response.data.data[0].id;

        // Save page access token in local storage
        window.localStorage.setItem('pageAccessToken', pageAccessToken);
        window.localStorage.setItem('pageId', pageId);
    });
  }

  displayTokenExpirationDate() {
    const today = new Date();
    const expiration = new Date(window.localStorage.getItem('userLongAccessTokenExpiration'));

    const diffenceInTime = expiration.getTime() - today.getTime();
    const diffenceInDays = Math.round(diffenceInTime / (1000 * 3600 * 24));

    if (diffenceInDays >= 1) {
      return (
        <span style={{color: 'green'}}>The Facebook token is still active for {diffenceInDays} days</span>
      );
    } else {
      return (
        <span style={{color: 'red'}}>The Facebook token is expired...</span>
      );
    }
  }

  goTo = (link) => {
    window.open(link);
  }

  render() {
    const { classes } = this.props;
   	return (
	    <Card>
	        <Title title="Connect to social networks" />
	        <CardContent>
						<div className={classes.root}>
              <Typography variant="h2" component="h2">FACEBOOK</Typography>
              <Typography variant="h5" component="h5"> {this.displayTokenExpirationDate()}</Typography>
              
              <button
                  onClick={(e) => { this.goTo(`https://www.facebook.com/dialog/oauth?client_id=${CLIENT_ID}&redirect_uri=${URL_REDIRECT}/social/connect&state=facebook&scope=pages_read_engagement,pages_manage_metadata,pages_manage_posts,pages_show_list`) }}
                  style={
                    {
                      backgroundColor: '#3B5997',
                      display: 'flex',
                      color: 'white',
                      fontSize: '20px',
                      cursor: 'pointer',
                      lineHeight: '35px',
                      fontWeight: 700,
                      borderRadius: '7px',
                      border: 'none',
                      boxShadow: '0 3px 5px #CCCCCC',
                      padding: '5px 20px 5px 10px',
                      margin: '20px 20px 0 0'
                    }
                      }>
                        <img width="36" src="https://s18955.pcdn.co/wp-content/themes/sharethis-custom/assets/images/facebookimg.png"/>Connect with Facebook</button>
			      </div>
	        </CardContent>
	    </Card>
    );
	}
}

Connect.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Connect);