import React, {Component} from 'react';

/* React-admin components */
import { Title, TextInput, NumberInput, ReferenceInput, BooleanInput,
	DateInput, SelectInput, SimpleForm, LongTextInput, ArrayInput, SimpleFormIterator, FormDataConsumer } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

/* Material UI components */
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import slugify from 'slugify';

class PatternCreatePatternStyle extends Component {

	constructor(props) {
    super(props);

    this.state = {
      pattern_styles: this.props.styles || 
      	this.props.files.sort((a,b) => {
      		return a.name.localeCompare(b.name);
      	}).map((file, index) => {
      		return {
      			name: file.name.substring(0, file.name.indexOf('.')),
      			minWidth: 0,
      			minHeight: 0,
      			index: index,
      			file: file.id
      		};
      	}),
      files: this.props.files || [],
      minWidthDefault: 0,
      minHeightDefault: 0
    };
    this.getData = this.getData.bind(this);
    this.applyDefaultDimension = this.applyDefaultDimension.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

	getData() {
		return this.state.pattern_styles;
	}

	applyDefaultDimension() {
		const currentStyles = this.state.pattern_styles;

		for (let style of currentStyles) {
			style.minWidth = this.state.minWidthDefault;
			style.minHeight = this.state.minHeightDefault;
		}

		this.setState({pattern_styles: currentStyles});
	}

	handleChange(data) {
		this.setState({pattern_styles: data.pattern_styles});
		this.setState({minWidthDefault: data.minWidthDefault});
		this.setState({minHeightDefault: data.minHeightDefault});
	}

	render() {

		const {pattern_styles} = this.state;

		return (
			<div>
				<SimpleForm toolbar={<span></span>} onChange={ (data) => this.handleChange(data)} >
			    <Typography style={{fontSize: 20, fontFamily: 'Roboto', fontWeight: 'bold'}}>
            Pattern styles
          </Typography>
          <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
		    <TextInput type="number" name="minWidthDefault" style={{margin: 5}} source="minWidthDefault" inputProps={{ step: 0.1 }} />
			<TextInput type="number" name="minHeightDefault" style={{margin: 5}} source="minHeightDefault" inputProps={{ step: 0.1 }} />
		    <Button
              variant="contained"
              color="primary"
              onClick={this.applyDefaultDimension}
              style={{margin: 5, height: 20}}
            >Apply</Button>
	    </div>
          <div style={{padding: '10px 25px 10px 25px', width: 'auto', backgroundColor: 'rgba(227, 242, 253, 0.3)'}}>
				    <ArrayInput defaultValue={pattern_styles} source="pattern_styles" style={{width: '100%'}}>
					    <SimpleFormIterator>
				        <TextInput type="text" name="name" style={{margin: 0, minWidth: 500}} source="name" />
					    <TextInput type="number" name="minWidth" style={{marginTop: 5, marginBottom: 5}} source="minWidth" inputProps={{ step: 0.1 }} />
					    <TextInput type="number" name="minHeight" style={{marginTop: 5, marginBottom: 5}} source="minHeight" inputProps={{ step: 0.1 }} />
				        <TextInput type="number" name="index" style={{margin: 0}} source="index" />
					    	<SelectInput source="file" choices={this.state.files} disabled="disabled" style={{minWidth: 500}} />
					    </SimpleFormIterator>
						</ArrayInput>
					</div>
			  </SimpleForm>
			</div>
		);
	}
}

export default PatternCreatePatternStyle;