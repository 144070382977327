import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* React-admin components */
import { Show, TabbedShowLayout, TextField, NumberField,ReferenceField, ArrayField,
  Datagrid, FunctionField, EditButton, DeleteButton, Tab, TextInput, SimpleForm, LongTextInput } from 'react-admin';

/* Material UI components */
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { TextField as MaterialTextField } from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
// Colors
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import orange from '@material-ui/core/colors/orange';
// Icons
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import PublishIcon from '@material-ui/icons/Publish';
import CancelIcon from '@material-ui/icons/Cancel';
import ShareIcon from '@material-ui/icons/Share';
import RestoreIcon from '@material-ui/icons/Restore';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

/* Utils */
import moment from 'moment';
import MySnackbarContentWrapper from '../layout/Alert';
import axios from 'axios';

const URL_API = process.env.REACT_APP_NODE_ENV == "production" ? 'https://api.theholzbrothers.com' : 'http://api.theholzbrothers.local';

const styles = {
  title: {
    fontSize: '26px',
    fontWeight: 'bold',
    display: 'inline'
  },
  cardActionStyle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  actionWrapper: {
    display: 'flex'
  },
  grid: {
    'div': {
      width: '100%'
    }
  },
  label: {
    paddingLeft: '0.5em'
  },
  publishButton: {
    color: green[700]
  },
  restoreButton: {
    color: green[700]
  },
  unpublishButton: {
    color: '#f44336'
  },
  icon: {
    fontSize: '10px',
    width: '20px',
    height: '20px'
  },
  downloadButtons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  fullWidth: {
    width: '100%'
  },
  flexItemWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center'
  },
  socialButtons: {
    width: '120px',
    textAlign: 'center',
    marginTop: 50
  }
}

const changeStatusPattern = (id, type) => {
  if (type === 'publish') {
    axios.put(`${URL_API}/admin/v1/patterns/${id}?update[]=status`, { status: 'online' });
  } else if (type === 'unpublish') {
    axios.put(`http://my-json-server.typicode.com/sebteillet/thb-api/patterns/${id}/status/unpublish`);
  } else if (type === 'deleted') {
    axios.put(`http://my-json-server.typicode.com/sebteillet/thb-api/patterns/${id}/status/deleted`);
  } else if (type === 'restore') {
    axios.put(`http://my-json-server.typicode.com/sebteillet/thb-api/patterns/${id}/status/restore`);
  }
}

const retryRenderingPattern = (id) => {
  axios.put(`http://my-json-server.typicode.com/sebteillet/thb-api/patterns/${id}/render`);
}

const downloadPatternDesign = (id, type) => {
  // TODO: Download 'psd' or 'fonts'
  console.log(`Not implemented yet! (id: ${id}, type: ${type})`);
}

const StatusChip = (status, deleted) => {
  if (deleted === true) {
    return <Chip
      label="Deleted"
      style={{background: red[700], color: 'white', height: '24px', margin: '0px 10px 0px 10px'}}
    />;
  } else if (status === 'published') {
    return <Chip
      label="Published"
      style={{background: green[800], color: 'white', height: '24px', margin: '0px 10px 0px 10px'}}
    />;
  } else if (status === 'rendering') {
    return <Chip
      label="Rendering"
      style={{background: orange[800], color: 'white', height: '24px', margin: '0px 10px 0px 10px'}}
    />;
  } else if (status === 'unpublished') {
    return <Chip
      label="Unpublished"
      style={{background: grey[300], color: 'black', height: '24px', margin: '0px 10px 0px 10px'}}
    />;
  } else {
    return <span></span>;
  }
}

const RenderingStatus = (rstatus) => {
  if (rstatus === 'error') {
    return (
      <Tooltip placement="top" title='Rendering status: error'>
        <div><ErrorIcon color="error" /></div>
      </Tooltip>
    );
  } else if (rstatus === 'done') {
    return (
      <Tooltip placement="top" title='Rendering status: done'>
        <CheckCircleIcon style={{color: green[500]}} />
      </Tooltip>
    );
  } else if (rstatus === 'ready') {
    return (
      <Tooltip placement="top" title='Rendering status: ready'>
        <HourglassFullIcon style={{color: grey[700]}} />
      </Tooltip>
    );
  }
}

const RenderingActionButtonBasedOnStatus = (status, rstatus, deleted, basePath, data, resource) => {
  if (status === 'ready') {
    return (
      <div>
        <span className="publishButton">
          <Button onClick={ () => changeStatusPattern(data.id, 'publish') } style={styles.publishButton}>
            <PublishIcon style={styles.icon} />
            <span style={styles.label}>Publish</span>
          </Button>
        </span>
        <DeleteButton basePath={basePath} record={data} resource={resource} />
      </div>
    );
  } else if (status === 'online') {
    return (
      <div>
        <span className="unpublishButton">
          <Button onClick={ () => changeStatusPattern(data.id, 'unpublish') } style={styles.unpublishButton}>
            <CancelIcon style={styles.icon} />
            <span style={styles.label}>Unpublish</span>
          </Button>
        </span>
        <DeleteButton basePath={basePath} record={data} resource={resource} />
      </div>
    );
  } else if (deleted !== true) {
    return (
      <DeleteButton basePath={basePath} record={data} resource={resource} />
    );
  } else {
    return (
      <span className="restoreButton">
        <Button onClick={ () => changeStatusPattern(data.id, 'restore') } style={styles.restoreButton}>
          <RestoreIcon style={styles.icon} />
          <span style={styles.label}>Restore</span>
        </Button>
      </span>
    );
  }
}

const RetryRendering  = (status, rstatus, id) => {
  if (status && (
    (status !== 'published' && rstatus === 'error') ||
      (status === 'unpublished' && rstatus === 'done'))) {
    return (
      <Tooltip placement="right" title='Retry rendering'>
        <IconButton onClick={ () => retryRenderingPattern(id) } color="inherit">
          <AutorenewIcon />
        </IconButton>
      </Tooltip>
    );
  }
  return ;
}

const PatternShowActions = ({ basePath, data, resource }) => (
  <CardActions style={styles.cardActionStyle}>
    <div style={styles.flexWrapper}>
      <Typography variant="title" style={styles.title}>{data ? data.name : ''}</Typography>
      { StatusChip(data ? data.status : null, data ? data.deleted : null) }
      { RenderingStatus(data ? data.rendering_status : null) }
      { RetryRendering(data ? data.status : null, data ? data.rendering_status : null, data ? data.id : null) }
    </div>
    <div style={styles.actionWrapper}>
      <EditButton basePath={basePath} record={data} resource={resource} />
      { RenderingActionButtonBasedOnStatus(
        data ? data.status : null,
        data ? data.rendering_status : null,
        data ? data.deleted : null,
        basePath, data, resource)
      }
    </div>
  </CardActions>
);

PatternShowActions.propTypes = {
  basePath: PropTypes.object,
  data: PropTypes.object,
  resource: PropTypes.object,
};

const PatternShowTitle = ({ record }) => {
  return <span>Pattern #{record.id} {record.name}</span>;
};

PatternShowTitle.propTypes = {
  record: PropTypes.object,
};

const RenderingStatusInfo = ({ record }) => {
  if (record.rendering_status === 'error') {
    return (
      <center>
        <MySnackbarContentWrapper
          variant="error"
          message="This pattern has one or many rendering error(s)."
          style={{padding: '0 12px', marginTop: '18px', width: 350}}
        />
      </center>
    );
  } else if (record.rendering_status === 'done') {
    return (
      <center>
        <MySnackbarContentWrapper
          variant="success"
          message="This pattern has successfully passed the rendering step."
          style={{padding: '0 12px', marginTop: '18px', width: 400}}
        />
      </center>
    );
  } else {
    return (
      <center>
        <MySnackbarContentWrapper
          variant="warning"
          message="This pattern is ready to be rendered or the rendering is in progress..."
          style={{padding: '0 12px', marginTop: '18px', width: 470}}
        />
      </center>
    );
  }
};

RenderingStatusInfo.propTypes = {
  record: PropTypes.object,
};

const PatternDownloadButtons = ({ record }) => {
  return (
    <div style={styles.downloadButtons}>
      <Button onClick={ () => downloadPatternDesign(record.id, 'psd') } color="primary">
        <CloudDownloadIcon color="primary" style={styles.icon} />
        <span style={styles.label}>Download PSD</span>
      </Button>
      <Button onClick={ () => downloadPatternDesign(record.id, 'fonts') } color="primary">
        <FontDownloadIcon color="primary" style={styles.icon} />
        <span style={styles.label}>Download fonts</span>
      </Button>
    </div>
  );
}

PatternDownloadButtons.propTypes = {
  record: PropTypes.object,
};

const CLIENT_ID = "766891450409970";
const CLIENT_SECRET = "6d6aec5c2fbc78a052fadd54b73da973";
const USER_ID = "428880641339192";
const PAGE_ID = "1626493337448851";

class PatternShow extends Component {

  constructor(props) {
    super(props);

    this.state = {
      content: '',
      background: 'EEE4DB',
      pattern: null,
      publishedOnFacebookPage: false,
      publishedOnFacebookPageLoading: false,
      publishedOnFacebookGroup: false,
      selectedModel: null
    }

    this.displayLatestColors = this.displayLatestColors.bind(this);
  }

  componentDidMount() {

    axios.get(`${URL_API}/admin/v1/patterns/${this.props.match.params.id}`)
      .then(response => {
        this.setState({
            pattern: response.data.pattern,
            selectedModel: response.data.pattern.styles[0].models[0].imageURL,
        });
      });
  }

  showTokenExpired() {
    const expirationStringDate = window.localStorage.getItem('userLongAccessTokenExpiration');
    const today = new Date();
    const expiration = new Date(expirationStringDate);

    const diffenceInTime = expiration.getTime() - today.getTime();
    const diffenceInDays = Math.round(diffenceInTime / (1000 * 3600 * 24));

    if (!expirationStringDate || diffenceInDays >= 1) {
      return (
        <center>
          <Chip
          style={{width: 250, margin: 10, textAlign: 'center', backgroundColor: 'red', color: 'white', fontWeight: 'bold'}}
          label="Facebook token is expired"
        />
        </center>
      );
    }
  }

  formatDate(date) {
    const momentDate = moment(date, moment.HTML5_FMT.DATETIME55_LOCAL_MS);
    if (momentDate.isValid()) {
      return (`${momentDate.format('DD MMM YYYY - HH:mm:ss')} (${momentDate.fromNow()})`);
    } else {
      return (' - ');
    }
  }

  saveBackgroundColor() {
    const existingColors = JSON.parse(window.localStorage.getItem('patternBackgroundColor')) || [];

    existingColors.indexOf(this.state.background) === -1 ?
      existingColors.push(this.state.background) : console.log('background already used');
    window.localStorage.setItem('patternBackgroundColor', JSON.stringify(existingColors));
  }

  publishOnFacebookPage(e) {
      e.preventDefault();
    this.saveBackgroundColor();
    this.setState({publishedOnFacebookPageLoading: true})
    const pageId = window.localStorage.getItem('pageId');
    const token = window.localStorage.getItem('pageAccessToken');

    axios.post(`https://graph.facebook.com/${pageId}/photos?access_token=${token}&url=${this.state.selectedModel.replace('upload/', 'upload/b_rgb:' + this.state.background + ',w_626,h_840,c_fill/l_webstore:assets:watermarks:frame:facebook/')}&published=false`)
      .then(response => {
        axios.post(`https://graph.facebook.com/${pageId}/feed?access_token=${token}&message=${this.state.content}`, {
        attached_media: [
          {"media_fbid": response.data.id}
          ]
        })
          .then(response => {
            if (response.data.id) {
              this.setState({publishedOnFacebookPage: true, publishedOnFacebookPageLoading: false});
            }
        });
    });
  }

  publishOnFacebookGroup() {
    const groupId = '433947357265779';
    const token = window.localStorage.getItem('userLongAccessToken');

    axios.post(`https://graph.facebook.com/${groupId}/feed?access_token=${token}&message=${this.state.content}`, {
      attached_media: [
        //{"media_fbid": response.data.id}
        ]
      })
        .then(response => {
          console.log(response.data);
          if (response.data.id) {
            this.setState({publishedOnFacebookGroup: true});
          }
      });
  }

  renderPatternModel() {
    const background = this.state.background.length === 6 ?
      this.state.background : 'EEE4DB';

    if (this.state.pattern) {
      return (
        <div style={styles.fullWidth}>
          <div style={styles.flexItemWrapper}>
          {
            this.state.pattern.styles.map((style) => (
              <img
                onClick={() => {this.setState({selectedModel: style.models[0].imageURL})}}
                style={{margin: 4, cursor: 'pointer', border: this.state.selectedModel === style.models[0].imageURL ? 'solid 3px green' : ''}}
                src={style.models[0].imageURL.replace('upload/', 'upload/f_auto,w_1000,c_crop,b_rgb:EEE4DB/h_42,w_42/')}
              />
            ))
          }
          </div>
          <div>
            <div><b>RESULT:</b></div>
            <img src={this.state.selectedModel ? this.state.selectedModel.replace('upload/', 'upload/f_auto,w_1000,c_crop,b_rgb:' + background + '/h_200,w_200/') : 'https://res.cloudinary.com/theholzbrothers/image/upload/f_auto,h_200,w_200/v1567401171/webstore/assets/the-holz-brothers-logo.jpg'} />
          </div>
        </div>
      );
    }
  }

  renderButtons() {
    return (
      <div style={styles.fullWidth}>
        <div style={styles.flexItemWrapper}>
          <div style={styles.socialButtons}>
            <button
              onClick={(e) => { this.publishOnFacebookPage(e) }}
              style={{backgroundColor: '#3B5997', display: 'flex', width: 220, color: 'white', fontSize: '20px', cursor: 'pointer', lineHeight: '35px', fontWeight: 700, borderRadius: '7px', border: 'none', boxShadow: '0 3px 5px #CCCCCC'}}><img width="36" src="https://s18955.pcdn.co/wp-content/themes/sharethis-custom/assets/images/facebookimg.png"/> {this.state.publishedOnFacebookPageLoading ? 'Publishing...' : 'Publish on Page'}</button>
      {
          this.state.publishedOnFacebookPage && <center
              style={{
                  color: 'white',
                  width: '200px',
                  backgroundColor: '#388E3C',
                  borderRadius: '7px',
                  padding: '5px 10px',
                  fontWeight: 200,
                  marginTop: '40px',
                  position: 'absolute',
              }}>Successfully published</center>
      }
          </div>
      </div>
      </div>
          // <div style={styles.socialButtons}>
          //   <img
          //     onClick={() => { this.publishOnFacebookGroup() } }
          //     style={{width: 70, cursor: 'pointer', border: this.state.publishedOnFacebookGroup == true ? 'solid 3px green' : 'none'}}
          //     src="https://s18955.pcdn.co/wp-content/themes/sharethis-custom/assets/images/facebookimg.png" />
          //   <center>Facebook Group</center>
          // </div>
          // <div style={styles.socialButtons}>
          //   <img
          //     onClick={() => { alert('Not implemented yet!')} }
          //     style={{width: 70, cursor: 'pointer'}}
          //     src="https://s18955.pcdn.co/wp-content/themes/sharethis-custom/assets/images/pinterest-pinimg.png" />
          //   <center>Pinterest</center>
          // </div>
          // <div style={styles.socialButtons}>
          //   <img
          //     onClick={() => { alert('Not implemented yet!')} }
          //     style={{width: 70, cursor: 'pointer'}}
          //     src="https://s18955.pcdn.co/wp-content/uploads/2018/02/instagramimgnew.png" />
          //   <center>Instagram</center>
          // </div>
          // <div style={styles.socialButtons}>
          //   <img
          //     onClick={() => { alert('Not implemented yet!')} }
          //     style={{width: 70, cursor: 'pointer'}}
          //     src="https://s18955.pcdn.co/wp-content/themes/sharethis-custom/assets/images/twitterimg.png" />
          //   <center>Twitter</center>
          // </div>

    );
  }

  displayLatestColors() {
    const colors = JSON.parse(window.localStorage.getItem('patternBackgroundColor')) || [];

    return (
      <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
        {
          colors.map((color) => (
            <span style={{margin: '0 5px'}}>
              <span onClick={() => { this.setState({background: color}) }}
                style={{
                  display: 'inline-block',
                  cursor: 'pointer',
                  color: '#0000EE',
                  textDecoration: 'underline',
                  fontSize: 10}}
                >
                  #{color}
                </span>
              <span style={{
                display: 'inline-block',
                backgroundColor: `#${color}`,
                verticalAlign: 'middle',
                margin: '0 2px',
                width: 18,
                height: 18}}>
              </span>
            </span>
          ))
        }
      </div>
    );
  }

  handleGenericValueChange(value, name) {
    this.setState({
      [name]: value
    });
  }

  render() {
    const { classes, ...other } = this.props;
    const { background } = this.state;
    return (
      <Show title={<PatternShowTitle />} actions={<PatternShowActions />} {...other}>
        <TabbedShowLayout>
          <Tab label="summary">
            <TextField source="id" />
            <TextField source="tag" />
            <ReferenceField source="category_id" reference="categories">
              <TextField source="name" />
            </ReferenceField>
            <FunctionField
              className={classes.input}
              render={record => this.formatDate(record.created_at)}
              source="created_at" />
            <FunctionField
              className={classes.input}
              render={record => this.formatDate(record.updated_at)}
              source="updated_at" />
            <FunctionField
              className={classes.input}
              render={record => this.formatDate(record.deleted_at)}
              source="deleted_at" />
          </Tab>
          <Tab label="design" path="design">
            <RenderingStatusInfo source="rendering_status" />
            <TextField source="designer" />
            <NumberField source="width" />
            <NumberField source="height" />
            <ArrayField source="rendering_errors">
              <Datagrid className={classes.grid}>
                <TextField source="type" />
                <TextField source="message" />
                <TextField source="code" />
              </Datagrid>
            </ArrayField>
            <PatternDownloadButtons source="id" />
          </Tab>
          <Tab label="webstore" path="webstore">
            <TextField source="description" />
            <TextField source="shortDescription" />
            <NumberField source="price" locales="en-EN" options={{ style: 'currency', currency: 'USD' }} />
          </Tab>
          <Tab label="share" path="share">
            {this.showTokenExpired()}
            <a href="/social/connect" target="_blank">Connect to social networks</a>
            <SimpleForm defaultValue={{ background: background }} toolbar={<span></span>}>
              <LongTextInput
                name="content"
                className={classes.fullWidth}
                onChange={(e) => this.handleGenericValueChange(e.target.value, "content")}
                source="content" />
              {this.displayLatestColors()}
              <TextInput
                name="background"
                onChange={(e) => this.handleGenericValueChange(e.target.value, "background")}
                className={classes.fullWidth}
                source="background" />
              {this.renderPatternModel()}
              {this.renderButtons()}
            </SimpleForm>
          </Tab>
        </TabbedShowLayout>
      </Show>
    );
  }
}

PatternShow.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PatternShow);