import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* React-admin components */
import { Title, TextInput, NumberInput, ReferenceInput, BooleanInput, DateInput, SelectInput, SimpleForm } from 'react-admin';

/* Material UI components */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
// Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PublishIcon from '@material-ui/icons/Publish';
// Colors
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';

/* Utils */
import axios from 'axios';

/* Steps components */
import UploadDropZone from './upload';
import PatternCreateForm from './PatternCreateForm'
import PatternCreateFaqs from './PatternCreateFaqs'
import PatternCreatePatternStyle from './PatternCreatePatternStyle'
import PatternCreateCustomizableFields from './PatternCreateCustomizableFields'
import PatternCreateModel from './PatternCreateModel'
import PatternMapFieldsToPatternStyle from './PatternMapFieldsToPatternStyle'

const URL_API = process.env.REACT_APP_NODE_ENV == "production" ? 'https://api.theholzbrothers.com' : 'http://api.theholzbrothers.local';
// TMP
const sleep = (milliseconds) => {
	return new Promise(resolve => setTimeout(resolve, milliseconds))
}

const styles = theme => ({
	root: {
		width: '100%',
	},
	button: {
		marginRight: theme.spacing.unit,
	},
	instructions: {
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
	},
	fullWidth: {
		width: '100%'
	}
});

class CreatePattern extends Component {

	constructor(props) {
		super(props);

		this.state = {
			activeStep: 0,
			stepOneWaitingFiles: 0,
			readyToNext: false,
			stepErrors: [],
			files: [],
			folder: '',
			pattern_data: {},
			pattern_styles: null,
			customizable_fields: null,
			faqs: null,
			models: null,
			currentFinalSteps: 1,
			published: false,
			apiErrors: '',
			patternId: null,
			driveUpload: null
		}

		this.pushFile = this.pushFile.bind(this);
		this.pushFolder = this.pushFolder.bind(this);
		this.removeFile = this.removeFile.bind(this);
		this.pushWaitingFile = this.pushWaitingFile.bind(this);
		this.renderFinalSteps = this.renderFinalSteps.bind(this);
		this.formatJSON = this.formatJSON.bind(this);
		this.restart = this.restart.bind(this);
		this.handleNext = this.handleNext.bind(this);
		this.publishPattern = this.publishPattern.bind(this);
		this.checkPatternRenderingStatus = this.checkPatternRenderingStatus.bind(this);
		this.checkPatternReadyStatus = this.checkPatternReadyStatus.bind(this);
	}

	getSteps() {
		return ['Upload files', 'Informations', 'FAQS', 'Styles', 'Customizable fields', 'Map fields to styles', 'Models'];
	}

	getStepContent(step) {
		switch (step) {
			case 0:
				return (
					<UploadDropZone
						ref="driveUpload"
						files={this.state.files}
						pushFile={this.pushFile}
						pushFolder={this.pushFolder}
						removeFile={this.removeFile}
						pushWaitingFile={this.pushWaitingFile}
					/>
				);
			case 1:
				return (
					<PatternCreateForm ref="patternData" data={this.state.pattern_data} />
				);
			case 2:
				return (
					<PatternCreateFaqs ref="patternFaqs" faqs={this.state.faqs} />
				);
			case 3:
				return (
					<PatternCreatePatternStyle ref="patternStylesData" files={this.state.files} styles={this.state.pattern_styles} />
				);
			case 4:
				return (
					<PatternCreateCustomizableFields ref="customizableFieldsData" data={this.state.customizable_fields} />
				);
			case 5:
				return (
					<PatternMapFieldsToPatternStyle ref="patternStylesDataWithCustomizableFields" files={this.state.files} styles={this.state.pattern_styles} fields={this.state.customizable_fields} />
				);
			case 6:
				return (
					<PatternCreateModel ref="modelsData" styles={this.state.pattern_styles} fields={this.state.customizable_fields} />
				);
			default:
				return 'Unknown step';
		}
	}

	formatJSON() {
		return {
			"pattern": {
				"categoryID": this.state.pattern_data.category_id,
				"name": this.state.pattern_data.name,
				"description": Object.values(this.state.pattern_data.description).join(''),
				"shortDescription": this.state.pattern_data.short_description,
				"designer": this.state.pattern_data.designer,
				"socialMediaPostContent": this.state.pattern_data.social_media_post_content,
				"releaseDate": this.state.pattern_data.release_date,
				"index": this.state.pattern_data.index,
				"price": this.state.pattern_data.price.toString(),
				"tag": this.state.pattern_data.public,
				"storageFolder": this.state.folder,
			},
			"patternStyles":
				this.state.pattern_styles.map(style => {
					return {
						name: style.name,
						index: style.index,
						uuid: this.state.files.find(file => { return file.id == style.file }).uuid,
						psdFileID: style.file,
						minLength: style.minHeight.toString(),
						minWidth: style.minWidth.toString(),
						linkedFields: style.fields || this.props.fields.map((field, index) => { return parseInt(index + 1); })
					};
				})
			,
			"styleFields":
				this.state.customizable_fields.map((field, index) => {
					return {
						"index": parseInt(index + 1),
						"name": field.name,
						"specs":
							field.field_specifications && field.field_specifications.map(spec => {
								return {
									"type": spec.type,
									"value": spec.value
								};
							})
					};
				})
			,
			"patternModels": [
				{
					"imageURL": "https://cloudinary.com/myimage1",
					"isDefault": true
				}
			],
			"styleModels":
				this.state.models.map((model, index) => {
					return {
						"isDefault": index === 0 ? true : false,
						"modelFields":
							model.fields.map(field => {
								return field.fields_data.map(data => {
									return {
										"styleFieldID": parseInt(data.id),
										"value": data.value
									};
								}).flat()
							}).flat()
					};
				}).flat()
			,
			"faq":
				this.state.faqs.map(faq => {
					return {
						"question": faq.question,
						"answer": faq.answer
					};
				})
		};
	}

	restart = () => {
		this.setState({ activeStep: 1 });
	}

	checkPatternReadyStatus = (patternId) => {
		let status = null;
		const self = this;
		axios.get(URL_API + '/admin/v1/patterns/' + patternId)
			.then(response => {
				status = response.data.pattern.status;
				if (status == 'ready' || status == 'error') {
					self.setState({ currentFinalSteps: self.state.currentFinalSteps + 1 });
					self.setState({ currentFinalSteps: self.state.currentFinalSteps + 1 });
				} else {
					sleep(20000).then(() => {
						self.checkPatternReadyStatus(patternId);
					});
				}
			});
	}

	checkPatternRenderingStatus = (patternId) => {
		let status = null;
		const self = this;
		axios.get(URL_API + '/admin/v1/patterns/' + patternId)
			.then(response => {
				status = response.data.pattern.status;
				if (status == 'rendering_models' || status == 'ready' || status == 'error') {
					self.setState({ currentFinalSteps: self.state.currentFinalSteps + 1 });
					self.checkPatternReadyStatus(patternId);
				} else {
					sleep(20000).then(() => {
						self.checkPatternRenderingStatus(patternId);
					});
				}
			});
	}

	handleNext = () => {
		const { activeStep } = this.state;

		if (this.refs.driveUpload) {
			this.setState({ driveUpload: this.refs.driveUpload });
		}

		this.setState({
			activeStep: activeStep + 1,
		});

		if (activeStep === 1) {
			this.setState({ pattern_data: this.refs.patternData.getPatternData() });
		} else if (activeStep === 2) {
			this.setState({ faqs: this.refs.patternFaqs.getData() || [] });
		} else if (activeStep === 3) {
			this.setState({ pattern_styles: this.refs.patternStylesData.getData() || [] });
		} else if (activeStep === 4) {
			this.setState({ customizable_fields: this.refs.customizableFieldsData.getData() || [] });
		} else if (activeStep === 5) {
			this.setState({ pattern_styles: this.refs.patternStylesDataWithCustomizableFields.getData() || [] });
		} else if (activeStep === 6) {
			this.setState({ models: this.refs.modelsData.getData() || [] });

			const self = this;
			sleep(1000).then(() => {
				const json = this.formatJSON();
				// Rename files in Google Drive
				this.state.driveUpload.updateAllFilenames(this.state.files, json, function () {
					// Send request to API
					axios.post(URL_API + '/admin/v1/patterns', json)
						.then(response => {
							console.log('NO ERROR');
							self.setState({ apiErrors: null });
							self.setState({ patternId: response.data.pattern.id });
							this.checkPatternRenderingStatus(response.data.pattern.id);
						})
						.catch(function (error) {
							if (error && error.response) {
								console.log('ERR:', error.response);
								self.setState({ apiErrors: error.response.data.errors.toString() });
							}
						});
				});
			});
		}
	};

	handleBack = () => {
		this.setState(state => ({
			activeStep: state.activeStep - 1,
		}));
	};

	pushWaitingFile() {
		this.setState({ stepOneWaitingFiles: this.state.stepOneWaitingFiles + 1 });
		this.setState({ readyToNext: false });
	}

	pushFolder(folderName) {
		this.setState({ folder: folderName });
	}

	pushFile(file) {
		this.setState(previousState => ({ files: [...previousState.files, file] }));
		if (this.state.files.length === this.state.stepOneWaitingFiles) {
			this.setState({ readyToNext: true });
		} else {
			this.setState({ readyToNext: false });
		}
	}

	removeFile(fileId) {
		const nfiles = this.state.files.filter(function (file) {
			return file.uuid !== fileId
		});
		const nWaitingFiles = this.state.stepOneWaitingFiles - 1;

		this.setState({ stepOneWaitingFiles: nWaitingFiles });
		this.setState({ files: nfiles });

		if (nfiles.length === nWaitingFiles && nWaitingFiles > 0) {
			this.setState({ readyToNext: true });
		} else {
			this.setState({ readyToNext: false });
		}
	}

	publishPattern() {
		const self = this;
		// Send request to API
		axios.put(`${URL_API}/admin/v1/patterns/${self.state.patternId}?update[]=status`, { status: 'online' })
			.then(response => {
				self.setState({ apiErrors: null });
				self.setState({ published: true });
			})
			.catch(function (error) {
				console.log('ERR:', error.response);
				self.setState({ apiErrors: error.response.data.errors.toString() });
			});
	}

	renderFinalSteps(step) {
		if (this.state.currentFinalSteps > step) {
			if (step !== 3 || (step === 3 && this.state.published === true)) {
				return (
					<CheckCircleIcon style={{ color: green[500], padding: 4 }} color={green[500]} />
				);
			} else {
				return (
					<Tooltip placement="left" title='Publish the pattern'>
						<IconButton onClick={this.publishPattern} style={{ width: 34, height: 34, marginRight: 14 }} color="inherit">
							<PublishIcon style={{ color: 'black', width: 26, height: 26 }} />
						</IconButton>
					</Tooltip>
				);
			}

		} else if (this.state.currentFinalSteps === step) {
			return (
				<CircularProgress style={{ width: 16, height: 16, padding: 8 }} color="primary" />
			);
		} else {
			return (
				<ArrowForwardIcon style={{ padding: 4 }} />
			);
		}
	}

	render() {
		const { classes } = this.props;
		const steps = this.getSteps();
		const { activeStep, readyToNext } = this.state;
		return (
			<Card>
				<Title title="Upload a new pattern" />
				<CardContent>
					<div className={classes.root}>
						<Stepper activeStep={activeStep}>
							{steps.map((label, index) => {
								const props = {};
								const labelProps = {};
								if (this.state.stepErrors.length > 0) {
									labelProps.error = (
										<Typography variant="caption" color="error">
											{this.state.stepErrors[0]}
										</Typography>
									);
								}
								return (
									<Step key={label} {...props}>
										{index < activeStep ?
											<StepLabel style={{ color: green[500] }} icon={<CheckCircleIcon style={{ fontSize: 28, marginTop: 2 }} color={green[500]} />} {...labelProps}>{label}</StepLabel>
											:
											<StepLabel {...labelProps}>{label}</StepLabel>
										}
									</Step>
								);
							})}
						</Stepper>
						<div>
							{activeStep === steps.length ? (
								<div>
									<Typography variant="caption" color="error" style={{ textAlign: 'center' }}>
										{this.state.apiErrors}
									</Typography>
									{this.state.apiErrors === null ?
										<div>
											<Typography style={{ textAlign: 'center', fontSize: 22 }} className={classes.instructions}>
												Congrats!<br /> Your pattern <span style={{ fontWeight: 'bold', fontSize: 28 }}>{this.state.pattern_data.name}</span> has been created!
				              </Typography>
											<div style={{ marginTop: 25, display: 'flex', justifyContent: 'center' }}>
												<Grid item xs={8} md={8}>
													<div className={classes.demo}>
														<List dense={true}>
															<ListItem>
																<ListItemIcon>
																	{this.renderFinalSteps(1)}
																</ListItemIcon>
																<ListItemText
																	primary="Script Photoshow validation"
																	secondary="This script will check the dimension of the canvas, the text fields name, fonts..."
																/>
															</ListItem>
															<ListItem>
																<ListItemIcon>
																	{this.renderFinalSteps(2)}
																</ListItemIcon>
																<ListItemText
																	primary="Rendering Pattern"
																	secondary="Images for webstore and social network will be generated"
																/>
															</ListItem>
															<ListItem>
																<ListItemIcon>
																	{this.renderFinalSteps(3)}
																</ListItemIcon>
																<ListItemText
																	primary="Ready to publish"
																	secondary={this.state.published ? 'This pattern is published! Waiting for $$$...' : 'You are just one click away from $$$!'}
																/>
															</ListItem>
														</List>
													</div>
												</Grid>
											</div>
											<Typography style={{ textAlign: 'center', fontSize: 16 }} className={classes.instructions}>
												I don't have time, <a href="#/patterns/2/show" style={{ color: blue[500] }}>go to pattern show page</a>
											</Typography>
										</div>
										:
										<div>
											<Button
												onClick={this.restart}
												className={classes.button}
											>
												Back
				                </Button>
										</div>
									}
								</div>
							) : (
									<div>
										<div>
											<Button
												disabled={activeStep === 0}
												onClick={this.handleBack}
												className={classes.button}
											>
												Back
			                </Button>
											<Button
												variant="contained"
												color="primary"
												disabled={readyToNext !== true}
												onClick={this.handleNext}
												className={classes.button}
											>
												{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
											</Button>
										</div>
										<Typography className={classes.instructions}>{this.getStepContent(activeStep)}</Typography>
										<div>
											<Button
												disabled={activeStep === 0}
												onClick={this.handleBack}
												className={classes.button}
											>
												Back
			                </Button>
											<Button
												variant="contained"
												color="primary"
												disabled={readyToNext !== true}
												onClick={this.handleNext}
												className={classes.button}
											>
												{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
											</Button>
										</div>
									</div>
								)}
						</div>
					</div>
				</CardContent>
			</Card>
		);
	}
}

CreatePattern.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(styles)(CreatePattern);