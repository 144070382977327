import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* React-admin components */
import { Edit, SimpleForm, TextInput, NumberInput, FunctionField, DisabledInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

/* Material UI components */
import { withStyles } from '@material-ui/core/styles';

/* Utils */
import moment from 'moment';


const styles = {
  input: {
    width: '100%'
  }
};

const Title = ({ record }) => {
  return <span>Category {record ? `"${record.name}"` : ''}</span>;
};

Title.propTypes = {
  record: PropTypes.object,
};

class CategoryEdit extends Component {

  formatDate(date) {
    const momentDate = moment(date, moment.HTML5_FMT.DATETIME55_LOCAL_MS);
    if (momentDate.isValid()) {
      return (`${momentDate.format('DD MMM YYYY - HH:mm:ss')} (${momentDate.fromNow()})`);
    } else {
      return (' - ');
    }
  }

  render() {
    const { classes, ...other } = this.props;
    return (
      <Edit title={<Title />} {...other}>
        <SimpleForm>
          <DisabledInput source="id" />
          <TextInput className={classes.input} source="name" />
          <NumberInput source="priority" />
          <RichTextInput source="description" addLabel={true} />
          <TextInput source="tag" />
          <FunctionField
            className={classes.input}
            render={record => this.formatDate(record.created_at)}
            source="created_at" />
          <FunctionField
            className={classes.input}
            render={record => this.formatDate(record.updated_at)}
            source="updated_at" />
          <FunctionField
            className={classes.input}
            render={record => this.formatDate(record.deleted_at)}
            source="deleted_at" />
        </SimpleForm>
      </Edit>
    );
  }
}

CategoryEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CategoryEdit);