import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

/* React-admin components */
import { Datagrid, EditButton, List, NumberField, TextField,
  ReferenceField, CardActions, CreateButton, ShowButton } from 'react-admin';

/* Material UI components */
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
// Colors
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';

/* Utils */
import moment from 'moment';

/* Filter */
import { PatternFilter } from './filter';


const styles = {};

const rowStyle = (record, index, defaultStyle = {}) => {
  if (record.deleted === true) {
    return {
      ...defaultStyle,
      backgroundColor: red[400],
    };
  }
  if (record.rendering_status === 'done')
    return {
      ...defaultStyle,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.rendering_status === 'error')
    return {
      ...defaultStyle,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.rendering_status === 'ready')
    return {
      ...defaultStyle,
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  return defaultStyle;
};

rowStyle.propTypes = {
  record: PropTypes.object,
  index: PropTypes.number,
  defaultStyle: PropTypes.object,
};

const PatternActions = ({ basePath }) => (
  <CardActions>
    <CreateButton label="Upload a new pattern" basePath={basePath} />
  </CardActions>
);

PatternActions.propTypes = {
  basePath: PropTypes.string,
};

const DateAgoField = ({ record = {} }) => (
  <span>{moment(record.created_at, moment.HTML5_FMT.DATETIME_LOCAL_MS).fromNow()}</span>
);

DateAgoField.propTypes = {
  record: PropTypes.object,
};

const RenderingPanel = ({ record }) => (
  <div style={{margin: 15}}>
    { record.rendering_errors.length === 0 ?
      <center><span>These is no rendering error for this pattern.</span></center>
      :
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { record.rendering_errors.map(error => (
              <TableRow key={error.code}>
                <TableCell>
                  {error.type}
                </TableCell>
                <TableCell>
                  {error.code}
                </TableCell>
                <TableCell>
                  {error.message}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    }
  </div>
);

RenderingPanel.propTypes = {
  record: PropTypes.object,
};

class PatternListTabbedDatagrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      draft: [],
      rendering: [],
      ready: [],
      online: [],
      tabs: [
        { id: 'draft', name: 'draft' },
        { id: 'rendering', name: 'rendering' },
        { id: 'ready', name: 'ready' },
        { id: 'online', name: 'online' },
      ]
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.status]) {
      return { ...state, [props.filterValues.status]: props.ids };
    }
    return null;
  }

  handleChange = (event, value) => {
    const { filterValues, setFilters } = this.props;
    setFilters({ ...filterValues, status: value });
  };

  render() {
    const { filterValues, ...props } = this.props;
    return (
      <Fragment>
        <Tabs
          fullWidth
          centered
          value={filterValues.status}
          indicatorColor="primary"
          onChange={this.handleChange}
        >
          {this.state.tabs.map(choice => (
            <Tab
              key={choice.id}
              label={choice.name}
              value={choice.id}
            />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === 'draft' && (
            <Datagrid rowStyle={rowStyle} {...props} ids={this.state.draft}>
              <TextField source="id" />
              <TextField source="name" />
              <ReferenceField source="category_id" reference="categories">
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="price" locales="en-EN" options={{ style: 'currency', currency: 'USD' }} />
              <TextField source="status" />
              <DateAgoField label="Publication Date" source="created_at" />
              <EditButton />
              <ShowButton />
            </Datagrid>
          )}
          {filterValues.status === 'rendering' && (
            <Datagrid expand={<RenderingPanel />} rowStyle={rowStyle} {...props} ids={this.state.rendering}>
              <TextField source="id" />
              <TextField source="name" />
              <ReferenceField source="category_id" reference="categories">
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="price" locales="en-EN" options={{ style: 'currency', currency: 'USD' }} />
              <TextField source="status" />
              <DateAgoField label="Publication Date" source="created_at" />
              <EditButton />
              <ShowButton />
            </Datagrid>
          )}
          {filterValues.status === 'ready' && (
            <Datagrid rowStyle={rowStyle} {...props} ids={this.state.ready}>
              <TextField source="id" />
              <TextField source="name" />
              <ReferenceField source="category_id" reference="categories">
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="price" locales="en-EN" options={{ style: 'currency', currency: 'USD' }} />
              <TextField source="status" />
              <DateAgoField label="Publication Date" source="created_at" />
              <EditButton />
              <ShowButton />
            </Datagrid>
          )}
          {filterValues.status === 'online' && (
            <Datagrid rowStyle={rowStyle} {...props} ids={this.state.online}>
              <TextField source="id" />
              <TextField source="name" />
              <ReferenceField source="category_id" reference="categories">
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="price" locales="en-EN" options={{ style: 'currency', currency: 'USD' }} />
              <TextField source="status" />
              <DateAgoField label="Publication Date" source="created_at" />
              <EditButton />
              <ShowButton />
            </Datagrid>
          )}
        </div>
      </Fragment>
    );
  }
}

PatternListTabbedDatagrid.propTypes = {
  ids: PropTypes.array,
  filterValues: PropTypes.object,
  'filterValues.status': PropTypes.string,
  setFilters: PropTypes.func,
};

class PatternList extends Component {

  render() {
    return (
      <List
        filterDefaultValues={{ status: 'online', deleted: false }}
        sort={{ field: 'created_at', order: 'name' }}
        actions={<PatternActions />}
        filters={<PatternFilter />} {...this.props}
      >
        <PatternListTabbedDatagrid />
      </List>
    );
  }
}

export default withStyles(styles)(PatternList);