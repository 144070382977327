import React, { Component } from 'react';

/* React-admin components */
import {
	Title, TextInput, NumberInput, ReferenceInput, BooleanInput,
	DateInput, SelectInput, SimpleForm, LongTextInput, ArrayInput, SimpleFormIterator
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

/* Material UI components */
import Typography from '@material-ui/core/Typography';

import slugify from 'slugify';

class PatternCreateForm extends Component {

	constructor(props) {
		super(props);

		let descString = '';
		let descObj = this.props.data ? this.props.data.description : null;
		if (descObj) {
			delete descObj.preventDefault;
		}
		for (var key in descObj) {
			descString += descObj[key];
		};

		this.state = {
			name: this.props.data.name || '',
			public: this.props.data.public || '',
			category_id: this.props.data.category_id || null,
			designer: this.props.data.designer || 'Hans',
			release_date: this.props.data.release_date || this.getFormattedDate(new Date()),
			social_media_post_content: this.props.data.social_media_post_content || '[New Pattern] ',
			price: this.props.data.price || 2.99,
			index: this.props.data.index || 0,
			description: descString,
			short_description: this.props.data.short_description || '',
		};

		this.generatePublic = this.generatePublic.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.getPatternData = this.getPatternData.bind(this);
	}

	getFormattedDate = (date) => {
		let year = date.getFullYear();
		let month = (1 + date.getMonth()).toString().padStart(2, '0');
		let day = date.getDate().toString().padStart(2, '0');
	  
		return year + '/' + month + '/' + day;
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	};

	handleDateChange(name, value) {
		this.setState({
			[name]: value
		});
	};

	handleGenericValueChange(value, name) {
		this.setState({
			[name]: value
		});
	}

	getPatternData() {
		return this.state;
	}

	generatePublic(event) {
		this.handleInputChange(event);
		let value = event.target.value.toLowerCase();

		value = value.replace(new RegExp(/the /g), ' ');
		value = value.replace(new RegExp(/with /g), '');
		value = value.replace(new RegExp(/ a /g), ' ');
		value = value.replace(new RegExp(/ an /g), ' ');
		value = slugify(value, {
			replacement: '-',
			remove: null,
			lower: true
		});

		value += '-scroll-saw-pattern';

		this.setState({ public: value, social_media_post_content: '[New pattern] ' + event.target.value});
	}

	render() {

		const { name, category_id, designer, price, index, description, short_description, release_date, social_media_post_content } = this.state;
		const pub = this.state.public;

		return (
			<div>
				<SimpleForm defaultValue={{ public: pub, name, category_id, designer, price, index, description, short_description, release_date, social_media_post_content }} toolbar={<span></span>}>
					<Typography style={{ fontSize: 20, fontFamily: 'Roboto', fontWeight: 'bold' }}>
						General
          			</Typography>
					<div style={{ width: '100%' }}>
						<TextInput name="name" onChange={(e) => this.generatePublic(e)} style={{ width: '100%' }} source="name" />
						<TextInput name="public" style={{ width: '100%', maxWidth: 500 }} onChange={(e) => this.handleInputChange(e)} source="public" /><br />
						<ReferenceInput source="category_id" onChange={(event, categoryId) => this.handleGenericValueChange(categoryId, "category_id")} reference="categories">
							<SelectInput name="category_id" style={{ width: '100%', maxWidth: 500 }} optionText="name" />
						</ReferenceInput>
					</div>

					<Typography style={{ fontSize: 20, fontFamily: 'Roboto', fontWeight: 'bold', marginTop: 32 }}>
						Design
					</Typography>
					<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
						<TextInput name="designer" onChange={(e) => this.handleInputChange(e)} source="designer" />
					</div>

					<Typography style={{ fontSize: 20, fontFamily: 'Roboto', fontWeight: 'bold', marginTop: 32 }}>
						Webstore
					</Typography>
					<div style={{ width: '100%' }}>
						<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
							<TextInput type="number" name="index" style={{ marginBottom: 25, marginRight: 40 }} onChange={(e) => this.handleInputChange(e)} source="index" />
							<TextInput type="number" name="price" style={{ marginBottom: 25 }} onChange={(e) => this.handleInputChange(e)} inputProps={{ step: 0.01 }} source="price" />
						</div>
						<RichTextInput name="description" style={{ width: '100%' }} onChange={(value) => this.handleGenericValueChange(value, "description")} source="description" addLabel={true} />
						<LongTextInput name="short_description" style={{ width: '100%', marginBottom: 25 }} onChange={(e) => this.handleInputChange(e)} source="short_description" />
						<DateInput name="release_date" style={{ width: '100%', marginBottom: 25 }} onChange={(e, value, f, name) => this.handleDateChange(name, value)} source="release_date" />
						<LongTextInput name="social_media_post_content" style={{ width: '100%', marginBottom: 25 }} onChange={(e) => this.handleInputChange(e)} source="social_media_post_content" />
					</div>
				</SimpleForm>
			</div>
		);
	}
}

export default PatternCreateForm;