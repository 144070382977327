import React from 'react';
import { Route } from 'react-router-dom';
import PatternCreate from './patterns/create';
import Connect from './social/connect';
//import PatternEdit from './patterns/edit';

export default [
    <Route exact path="/patterns/create" component={PatternCreate} />,
    //<Route exact path="/patterns/:id" component={PatternEdit} />
    <Route exact path="/social/connect" component={Connect} />,
];