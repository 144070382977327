import React from 'react';

/* React-admin components */
import { Login, LoginForm } from 'react-admin';

/* Material UI components */
import { withStyles } from '@material-ui/core/styles';


const styles = ({
  main: { background: '#EDD5B3', backgroundSize: 'cover', },
  avatar: {
    background: 'url(images/logo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 80,
    width: 80,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: 40
  },
  icon: { display: 'none' },
});

const CustomLoginForm = withStyles({
  button: { background: '#F15922' },
})(LoginForm);

const CustomLoginPage = props => (
  <Login
    loginForm={<CustomLoginForm />}
    backgroundImage="https://ak9.picdn.net/shutterstock/videos/4055689/thumb/8.jpg"
    {...props}
  />
);

export default withStyles(styles)(CustomLoginPage);