import React from 'react';

/* React-admin components */
import { Filter, SelectInput, TextInput } from 'react-admin';

export const CategoryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput
      source="deleted"
      label="Status"
      choices={[
        { id: true, name: 'Deleted' },
        { id: false, name: 'Active' }
      ]}
      alwaysOn
    />
  </Filter>
);