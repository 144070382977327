import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* React-admin components */
import { Create, SimpleForm, TextInput, NumberInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

/* Material UI components */
import { withStyles } from '@material-ui/core/styles';

/* Utils */
import axios from 'axios';

const styles = {
  input: {
    width: '100%'
  }
};

const Title = () => {
  return <span>Create new category</span>;
};

class CategoryCreate extends Component {

  render() {
    const { classes, ...other } = this.props;
    return (
      <Create title={<Title />} {...other}>
        <SimpleForm>
          <TextInput className={classes.input} source="name" />
          {/*<NumberInput source="index" />*/}
          <RichTextInput source="description" addLabel={true} />
          <TextInput source="shortDescription" />
        </SimpleForm>
      </Create>
    );
  }
}

CategoryCreate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CategoryCreate);