import React from 'react';

/* React-admin components */
import { Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin';

export const PatternFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <ReferenceInput
      source="category_id"
      reference="categories"
      sort={{ field: 'id', order: 'ASC' }}
      alwaysOn
    >
      <SelectInput source="name" />
    </ReferenceInput>
    <SelectInput
      source="deleted"
      label="Status"
      choices={[
        { id: true, name: 'Deleted' },
        { id: false, name: 'Active' }
      ]}
      alwaysOn
    />
  </Filter>
);